import React, { useEffect, useState } from "react";
import axios from "axios";
import QRCode from "qrcode";
import "tailwindcss/tailwind.css";
import Logo from "./assets/channels4_banner.jpg";
import Logo2 from "./assets/logo3.png";

import Bg01 from "./assets/bg-01.jpg";
import ReactPixel from "react-facebook-pixel";

function DesktopView() {
  // Adicione o Pixel do Facebook ao carregar o componente
  useEffect(() => {
    // Inicializar o Pixel
    const options = {
      autoConfig: true, // Opcional, define configurações automáticas
      debug: false // Opcional, define o modo debug
    };
    ReactPixel.init("1022474222965029", {}, options);

    // Registrar visualização de página
    ReactPixel.pageView();
  }, []);

  const [step1, setStep1] = useState(true);
  const [stepPagar, setStepPagar] = useState(false);

  let valorfinalpagar = null;

  valorfinalpagar = 4990;

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      setFormData((prevState) => ({
        ...prevState,
        disponibilidade: checked
          ? [...prevState.disponibilidade, value]
          : prevState.disponibilidade.filter((dia) => dia !== value)
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const [dataGetw, setDataGetw] = useState(null);
  const [loading, setLoading] = useState(false); // Estado para o carregamento

  const [qrCodeData, setQrCodeData] = useState("");
  const [codigoCopiado, setCodigoCopiado] = useState("");

  let copiaeCOlabs = "";

  const togglePopupPagar = () => {
    setStepPagar(true);
    setDataGetw(null);

    handleSelection();
  };

  const handleSelection = () => {
    setLoading(true);

    // Multiplica o valor por 100 para adicionar dois zeros à direita
    const formattedAmount = valorfinalpagar;

    const data = JSON.stringify({
      amount: formattedAmount // Passa o valor formatado
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://rghocgzrwi.execute-api.us-east-1.amazonaws.com/dev/sdfsfsd9563d",
      headers: {
        accept: "application/json",
        "content-type": "application/json"
      },
      data: data
    };

    // Fazendo a requisição com Axios
    axios
      .request(config)
      .then(async (response) => {
        copiaeCOlabs = response.data.pix.qrcode;

        setDataGetw(response.data);

        // Enviar evento de Checkout para o Facebook Pixel
        ReactPixel.track("Checkout", {
          value: formattedAmount // Valor do pagamento
        });

        // Obtém o código QR do PIX e gera o QR Code
        if (response.data?.pix?.qrcode) {
          copiaeCOlabs = response.data.pix.qrcode;
          await generateQRCodeBase64();
        } else {
          throw new Error("QR Code não encontrado na resposta da API");
        }
      })
      .catch((error) => {
        console.error("Erro na requisição: ", error);
      });
  };

  // Função para gerar o QR Code
  const generateQRCodeBase64 = async () => {
    try {
      // Gerar QR Code base64
      const qrCodeBase64 = await QRCode.toDataURL(copiaeCOlabs, {
        width: 300, // Ajuste de largura
        margin: 2 // Margem ao redor do QR Code
      });
      setQrCodeData(qrCodeBase64);
      setLoading(false);
    } catch (err) {
      console.error("Erro:", err);
    }
  };

  const handleCopy = () => {
    const qrCodeText = dataGetw.pix.qrcode;

    navigator.clipboard
      .writeText(qrCodeText)
      .then(() => {
        setCodigoCopiado("Código copiado com sucesso!");
      })
      .catch((err) => {
        console.error("Erro ao copiar o código:", err);
        alert("Erro ao copiar o código.");
      });
  };

  const [formData, setFormData] = useState({
    nome: "",
    idade: "",
    cidade: "",
    telefone: "",
    instagram: "",
    tipoVideo: "",
    interesse: "",
    disponibilidade: [],
    restricao: ""
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    alert("Formulário enviado com sucesso!");
  };

  return (
    <>
      {step1 && (
        <div className="bg-[#f5f5f5]">
          <div className="relative">
            <div className="  bg-[#f5f5f5] justify-center items-center gap-4">
              {/* Logo para telas maiores */}
              <img
                src={Logo}
                className="hidden sm:block w-full"
                alt="Imagem Base64"
              />

              {/* Logo2 para telas menores */}
              <img
                src={Logo2}
                className="sm:hidden w-full mb-10 -mt-16"
                alt="Imagem Base64"
              />
            </div>
          </div>

          <div className="relative flex flex-col p-2 items-center w-full max-w-[90%] sm:max-w-[70%] md:max-w-[50%] lg:max-w-[40%] mx-auto bg-[#212b36] rounded-t-2xl -mt-10">
            {/* Imagem sobreposta */}
            <div className="   justify-center items-center mt-1">
              {!dataGetw && !stepPagar && (
                <>
                  <div className="relative  ">
                    {/* Imagem */}
                    <img
                      src={Bg01}
                      className="w-full rounded-2xl"
                      alt="Imagem Base64"
                    />
                  </div>
                  <div className="p-6 bg-gray-100 rounded-lg shadow-md mt-5">
                    <h2 className="text-2xl font-bold mb-4 uppercase text-center">
                      Formulário de Inscrição
                    </h2>
                    <p className="mb-6 text-gray-700">
                      Fala, galera! Muito obrigado pelo interesse em participar
                      nos vídeos do nosso canal! Só pra lembrar que, ao
                      preencher esse formulário, você estará compartilhando suas
                      informações conosco para que possamos entrar em contato e
                      te oferecer uma oportunidade única.
                    </p>

                    <p className="mb-6 text-gray-700 font-semibold">
                      Para garantir sua participação, a taxa de inscrição é de
                      apenas{" "}
                      <span className="text-[#259057] text-[15px] font-extrabold">
                        R$49,90
                      </span>
                      . Esse valor é simbólico e garante que você esteja em um
                      processo sério e organizado, além de te dar acesso a
                      benefícios exclusivos, como prioridade de contato e mais
                      chances de aparecer nos nossos vídeos!
                    </p>

                    <p className="mb-6 text-gray-700">
                      Aproveite essa oportunidade para se conectar com a gente e
                      se destacar no nosso conteúdo! Não perca essa chance única
                      de fazer parte da nossa comunidade.
                    </p>

                    <label className="block mb-4">
                      Qual é o seu nome? *
                      <input
                        type="text"
                        name="nome"
                        value={formData.nome}
                        onChange={handleChange}
                        className="w-full p-2 border rounded mt-1"
                        required
                      />
                    </label>

                    <label className="block mb-4">
                      Qual é a sua idade? *
                      <input
                        type="number"
                        name="idade"
                        value={formData.idade}
                        onChange={handleChange}
                        className="w-full p-2 border rounded mt-1"
                        required
                      />
                    </label>

                    <label className="block mb-4">
                      Qual é a sua cidade? *
                      <input
                        type="text"
                        name="cidade"
                        value={formData.cidade}
                        onChange={handleChange}
                        className="w-full p-2 border rounded mt-1"
                        required
                      />
                    </label>

                    <label className="block mb-4">
                      Qual é o seu telefone? (whatsapp) *
                      <input
                        type="tel"
                        name="telefone"
                        value={formData.telefone}
                        onChange={handleChange}
                        className="w-full p-2 border rounded mt-1"
                        required
                      />
                    </label>

                    <label className="block mb-4">
                      Qual é o seu @ no Instagram? *
                      <input
                        type="text"
                        name="instagram"
                        value={formData.instagram}
                        onChange={handleChange}
                        className="w-full p-2 border rounded mt-1"
                        required
                      />
                    </label>

                    <label className="block mb-4">
                      Por que você gostaria de participar do nosso conteúdo? *
                      <textarea
                        name="interesse"
                        value={formData.interesse}
                        onChange={handleChange}
                        className="w-full p-2 border rounded mt-1"
                        required
                      ></textarea>
                    </label>

                    <fieldset className="mb-4">
                      <legend className="font-semibold mb-2">
                        Qual é a sua disponibilidade para uma gravação? *
                      </legend>
                      {[
                        "Segunda-Feira",
                        "Terça-Feira",
                        "Quarta-Feira",
                        "Quinta-Feira",
                        "Sexta-Feira",
                        "Sábado",
                        "Domingo"
                      ].map((dia) => (
                        <label key={dia} className="block">
                          <input
                            type="checkbox"
                            name="disponibilidade"
                            value={dia}
                            checked={formData.disponibilidade.includes(dia)}
                            onChange={handleChange}
                            className="mr-2"
                          />
                          {dia}
                        </label>
                      ))}
                    </fieldset>

                    <label className="block mb-4">
                      Alguma restrição sobre a resposta acima? (Exemplo: "posso
                      segunda, mas só na parte da tarde.")
                      <textarea
                        name="restricao"
                        value={formData.restricao}
                        onChange={handleChange}
                        className="w-full p-2 border rounded mt-1"
                      ></textarea>
                    </label>

                    <button
                      onClick={togglePopupPagar}
                      className="w-full bg-[#2a2c38] text-white py-2 rounded  uppercase transition"
                    >
                      Finalizar Inscrição
                    </button>
                  </div>
                </>
              )}
              {stepPagar && (
                <>
                  {qrCodeData && dataGetw ? (
                    <>
                      <div className="flex justify-center items-center">
                        <img
                          src={qrCodeData}
                          alt="QR Code"
                          className="w-64 my-7 h-64"
                        />
                      </div>

                      <div className="text-center mb-5">
                        <p className="text-slate-200 text-sm mt-3">
                          <strong className="text-[22px] font-extralight">
                            VALOR:
                          </strong>{" "}
                          <p className="text-[32px] mt-2 font-extrabold">
                            R$ 49,90
                          </p>
                        </p>
                      </div>

                      <div className="text-center mb-5 justify-center items-center">
                        <p className="text-slate-200 text-sm mt-3">
                          <strong className="text-red-600">Importante:</strong>{" "}
                          Não feche esta página até concluir o pagamento.
                        </p>
                      </div>

                      {/* Ajuste da altura usando Tailwind */}
                      <p className="text-slate-200 text-sm font-bold justify-center items-center text-center">
                        Para efetuar o pagamento, leia o código QR com a câmera
                        do celular ou copie o código abaixo e selecione a opção
                        "copiar e colar" no aplicativo do seu banco.
                      </p>

                      <input
                        type="text"
                        value={dataGetw.pix.qrcode}
                        disabled
                        className="text-sm text-white p-2 font-semibold border-2 mt-4 border-black/60 py-2 px-2 w-full rounded-md code"
                      />

                      {codigoCopiado && (
                        <p className="text-green-600 my-2 text-center text-sm font-bold">
                          {codigoCopiado}
                        </p>
                      )}

                      <div className="flex justify-center items-center">
                        <button
                          onClick={handleCopy}
                          className="bg-gradient-to-t from-[#bde92f] to-[#bde92f]  text-black text-sm font-bold py-3 rounded-full mt-6 uppercase w-full lg:w-1/2 mx-auto mb-4 hover:bg-blue-600 transition"
                        >
                          Copiar código
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="p-7 flex flex-col justify-center items-center gap-4 ">
                      <span className="text-slate-200 mb-5 text-lg text-center">
                        Estamos preparando o QR Code de sua inscrição. Por
                        favor, aguarde...
                      </span>
                    </div>
                  )}
                </>
              )}

              <div></div>
            </div>
          </div>

          <div className="bg-[#f5f5f5] p-4 text-white font-light text-sm">
            <div className="flex items-center justify-center">
              <p className="text-justify font-normal mt-10 mb-4 text-[20px] text-[#090909]">
                Episódios novos todas as Terças às 20h, sempre repletos de
                desafios pra te garantir muito entretenimento, descontração,
                humor, resenha monstra e risada pra c4r%lh0!
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function Index() {
  return (
    <div>
      <DesktopView />
    </div>
  );
}

export default Index;
